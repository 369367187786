/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.rootContent {
  padding: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-edit {
  border: none;
  border-radius: 4px;
  background-color: teal;
  color: white;
  margin: 0 5px;
  padding: 4px;
  width: 50px;
}

.btn-delete {
  border: none;
  border-radius: 4px;
  background-color: #e40030;
  color: white;
  margin: 0 5px;
  padding: 4px;
  width: 50px;
}

.btn-add {
    border: none;
    border-radius: 4px;
    background-color: teal;
    color: white;
    min-width: 140px;
    height: 35px;
    margin: 0 5px;
}
.btn-next {
  border: none;
  border-radius: 4px;
  background-color: teal;
  color: white;
  min-width: 140px;
  height: 35px;
  float: right;
}

.no-record-error {
  padding: 10px;
  color: #e40030;
  text-align: center;
}

input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  resize: vertical;
  background-color: white;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: teal !important;
  border-width: 1px !important;
}

.MuiPaper-root,
.MuiDialog-paper {
  min-width: 40%;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.submitBtn {
  background-color: #15717d;
  color: white;
  padding: 3px 20px;
  border-color: #115d67;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin: 20px 5px 5px 5px;
}
.reviewBtn {
  border: none;
  border-radius: 4px;
  background-color: #e40030;
  color: white;
  min-width: 140px;
  height: 35px;
}

.cancelBtn {
  background-color: #fff;
  color: #000000;
  padding: 3px 20px;
  border-color: #ccc;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin: 20px 5px 5px 5px;
}

.cancelBtn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.formcontainer {
  border-radius: 5px;
  background-color: #efefed;
  padding: 20px;
  margin-top: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.table {
  font-size: 14px;
}
.table thead th {
    vertical-align: top !important;
}

.table tr td:last-child {
  width: 150px;
  white-space: nowrap;
}
.table-container {
  font-size: 14px;
  border: none;
}
.table-container thead th {
  vertical-align: top !important;
}
.table-container input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  resize: vertical;
  background-color: white;
}
.table-container tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Clear floats after the columns */
.formrow:after {
  content: "";
  display: table;
  clear: both;
}

.dropdown-item:active {
  background-color: teal;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}

.notFoundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notFoundHeader {
  font-size: 6rem;
  line-height: 8rem;
}

.btn-landing {
    border: none;
    border-radius: 15px;
    background-color: teal;
    color: white;
    min-width: 140px;
    height: 120px;
    margin: 0 5px;
    margin-top: 50px;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    font-size: 25px;
    font-weight: 700;
    font-family: arial;
}

.btn-proceed {
    border: none;
    border-radius: 7px;
    background-color: #e40030;
    color: white;
    margin: 0 5px;
    padding: 4px;
    width: 14%;
    height: 5vh;
    /* height: 40px; */
    /* width: 40%; */
    margin-left: 44%;
    margin-right: 44%;
    margin-top: 10px;
    font-weight: 700;
    font-family: arial;
}


.disclaimer-wrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disclaimer-container {
    display: flex;
    flex-direction: column;
    width: 50vw;
    max-width: 630px;
    align-items: center;
    justify-content: center;
    border: solid 2px rgb(110, 108, 108);
    border-radius: 10px;
    padding: 15px;
}

.disclaimer-head {
    color: #E81E23;
}

.disclaimer-para {
    text-align: justify;
}
